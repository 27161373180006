import React from "react";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import swal from "sweetalert";
import Textfield from "../Utills/Textfield";
import CheckBox from "../Utills/CheckBox";
import * as backend from "../../backend";
import { downloadAsFile } from "../../utils/download";

export default function Corporation(props) {
  const { data, handleChange, validator, isView } = props;
  const fileTypes = [
    "JPEG",
    "PNG",
    "GIF",
    "PDF",
    "DOC",
    "DOCX",
    "JPG",
    "CSV",
  ];

  const onSelectFiles = (files) => {
    if (files?.length > 10) {
      swal("", "You can select a maximum of 10 files only", "info");
    } else {
      // Filter out initial [{ url: null, id: null }] item
      handleChange({ target: { name: "files", value: [...data.files, ...files].filter((f) => f.url || f.name) } });
    }
  };

  const removeFile = (index) => {
    handleChange({
      target: {
        name: "files",
        value: data.files.filter((_, i) => i !== index),
      },
    });
  };

  const viewFile = async (file) => {
    if (typeof file?.url == "string") {
      const blob = await backend.image.get(file.id);
      downloadAsFile(blob, file.url);
    } else {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl);
    }
  };

  validator?.current?.purgeFields();

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 mt-1">
        {!isView && (
          <CheckBox
            disabled={isView}
            name="corp_investment"
            id="corp_investment"
            value={data.corp_investment}
            onChange={handleChange}
            label="Are you opening a corporate investment account?"
          />
        )}
      </div>
      {data.corp_investment ? (
        <>
          <div className="mb-1 col-12 col-md-6">
            <Textfield
              disabled={isView}
              validator={validator}
              name="business_name"
              id="business_name"
              onChange={handleChange}
              value={data.business_name}
              label="Business Name"
            />
          </div>
          <div className="mb-1 col-12 col-md-6">
            <Textfield
              disabled={isView}
              validator={validator}
              name="business_number"
              title={
                isView
                  ? null
                  : "This is a 9 or 15 digit number assigned by Canada Revenue Agency. \nThe number should appear on tax returns and is typically structured 123 456 789 (RC 0001)”. If someone does not enter a BIN, please have a warning “Required. BIN should be 9 or 15 digits, typically formatted 123 456 789 (RC 0001)"
              }
              id="business_number"
              onChange={handleChange}
              value={data.business_number}
              label="Business Identification Number (BIN)"
            />
          </div>
          {isView ? (
            <div className="col-12">
              <h5 className="my-2">
                <b>Uploaded files:</b>
              </h5>
              {data.files.filter((f) => f.url || f.name).map(
                (f, i) =>
                  f && (
                    <div key={i}>
                      {i + 1}){" "}
                      <a className="fileName" onClick={() => viewFile(f)}>
                        {typeof f.url == "string" ? f.url : f.name}
                      </a>
                    </div>
                  ),
              )}
            </div>
          ) : (
            <>
              <div className="form-group pr-2 mb-1 col-12 col-md-5 fileUpload">
                <FileUploader
                  multiple
                  handleChange={onSelectFiles}
                  name="files"
                  types={fileTypes}
                  maxSize="100"
                >
                  <div className="DragDrop" style={{ height: "100%" }}>
                    <div className="uppy-Root" dir="ltr">
                      <button
                        type="button"
                        className="uppy-u-reset uppy-DragDrop-container uppy-DragDrop--isDragDropSupported"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div className="uppy-DragDrop-inner">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            className="uppy-c-icon uppy-DragDrop-arrow"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0"
                              fillRule="evenodd"
                            ></path>
                          </svg>
                          <div className="uppy-DragDrop-label">
                            Drop here or
                            <span className="uppy-DragDrop-browse">
                              {" "}
                              browse
                            </span>
                          </div>
                          <span className="uppy-DragDrop-note">All files</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </FileUploader>
              </div>
              <div className="form-group mb-1 col-12 col-md-7">
                <h5 className="my-2">
                  <b>Upload the following files:</b>
                </h5>

                <ul className="mb-1 ml-1">
                  <li className="mb-0">Driver’s License and Passport</li>
                  <li className="mb-0">Copy of Void Cheque</li>
                  <li className="mb-0">Certificate of Incorporation</li>
                  <li className="mb-0">Articles of Incorporation</li>
                  <li className="mb-0">Annual Returns or Proof of Filing</li>
                </ul>

                <h5 className="my-2">
                  <b>Files:</b>
                </h5>
                <div className="row mx-n1 no-gutters">
                  {data.files.filter((f) => f.url || f.name).map(
                    (file, i) =>
                      file && (
                        <div
                          className="file col-12 col-md-6"
                          id={file.name}
                          key={file.name}
                          title={file.name}
                        >
                          <div className="row flex-row flex-nowrap align-items-center card mx-1 mb-1 p-060 shadow-none border">
                            <div className="col-auto p-0">
                              <div className="avatar-sm">
                                <span className="m-2 avatar-title bg-light text-secondary rounded">
                                  <i className="mdi mdi-file-outline font-28"></i>
                                </span>
                              </div>
                            </div>
                            <div className="col px-0 overflow-hidden">
                              <div className="font-weight-medium overflow">
                                {file.name ?? file.url}
                              </div>
                              <p className="mb-0 text-muted font-13">
                                {file.lastModifiedDate ? `${moment(file.lastModifiedDate).format("L")} ${moment(file.lastModifiedDate).format("LT")} ` : 'Uploaded'}
                                <span className="mb-0 ml-1 font-13 overflow floatRight">
                                  {file.size && Math.floor(file.size / 1000) + "KB"}
                                </span>
                              </p>
                            </div>
                            <div className="col-auto p-0">
                              <button
                                className="btn btn-lightx px-1 "
                                type="button"
                                onClick={() => removeFile(i)}
                              >
                                <i
                                  className="mdi mdi-delete"
                                  style={{
                                    color: "red",
                                    fontSize: "18px",
                                  }}
                                ></i>
                              </button>
                              <button
                                className="btn btn-lightx px-1 "
                                type="button"
                                onClick={() => viewFile(file)}
                              >
                                <i
                                  className="mdi mdi-eye"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        isView && "None"
      )}
    </div>
  );
}
