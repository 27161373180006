import React from "react";
import RadioButton from "../Utills/RadioButton";
import CheckBox from "../Utills/CheckBox";

export default function Objectives(props) {
  const { data, handleChange, validator, isView } = props;
  const riskTolerence = ["Low", "Medium", "High"];
  const investment_objectives = ["Income", "Balanced", "Growth"];
  const investmentExperience = [
    { label: "Stocks", id: "stocks" },
    { label: "Bonds", id: "bonds" },
    { label: "Mutual Funds", id: "mutual_funds" },
    { label: "None", id: "none" },
  ];
  const investment_knowledge = ["Limited", "Average", "Good", "Excellent"];
  const investmentTime = ["Under 3 years", "3 – 10 years", "10 years+"];

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="form-group mb-1 col-12 col-md-6">
        <label for="" className="col-form-label">
          a) Your risk tolerance:
        </label>
        {riskTolerence.map((r, i) => (
          <RadioButton
            key={i}
            disabled={isView}
            name="risk_tolerance"
            value={data.risk_tolerance}
            onChange={handleChange}
            id={`risktoler_${i}`}
            label={r}
          />
        ))}
      </div>

      <div className="form-group mb-1 col-12 col-md-6">
        <label for="" className="col-form-label">
          b) Your investment objective:
        </label>
        {investment_objectives.map((o, i) => (
          <RadioButton
            key={i}
            disabled={isView}
            name="investment_objective"
            value={data.investment_objective}
            onChange={handleChange}
            id={`investobject_${i}`}
            label={o}
          />
        ))}
      </div>

      <div className="form-group mb-1 col-12 col-md-6">
        <label for="" className="col-form-label">
          c) Your investment experience:
        </label>
        {investmentExperience.map((e, i) => (
          <CheckBox
            key={i}
            disabled={isView}
            id={`investment_exp_${e.id}`}
            name={`investment_exp_${e.id}`}
            value={data[`investment_exp_${e.id}`]}
            onChange={handleChange}
            label={e.label}
            className="ml-2"
            isCheckBox
          />
        ))}
      </div>

      <div className="form-group mb-1 col-12 col-md-6">
        <label for="" className="col-form-label">
          d) Your investment knowledge:
        </label>
        {investment_knowledge.map((r, i) => (
          <RadioButton
            key={i}
            disabled={isView}
            name="investment_knowledge"
            value={data.investment_knowledge}
            onChange={handleChange}
            id={`investment_knowledge_${i}`}
            label={r}
          />
        ))}
      </div>

      <div className="form-group mb-1 col-12 col-md-6">
        <label for="" className="col-form-label">
          e) Your estimated investment time horizon:
        </label>
        {investmentTime.map((r, i) => (
          <RadioButton
            key={i}
            disabled={isView}
            name="investment_time"
            value={data.investment_time}
            onChange={handleChange}
            id={`investment_time_${i}`}
            label={r}
          />
        ))}
      </div>
    </div>
  );
}
