import React, { useRef, useState, useEffect } from "react";
import Textfield from "../../components/Utills/Textfield";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { requestOTP, resetPassword } from "../../backend/auth";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Utills/Loader";

export default function ForgetPassword() {
  const [data, setData] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [displayResetForm, setDisplayResetForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Required!",
      },
    }),
  );

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/welcome");
    }
  }, []);

  const onClickSubmit = () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      if (displayResetForm) {
        resetPassword(data)
          .then((res) => {
            if (res.success) {
              setIsLoading(false);
              swal("Password updated successfully! Click Ok to log in").then(
                (value) => {
                  navigate("/login");
                },
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        requestOTP({ email: data.email })
          .then((res) => {
            setIsLoading(false);
            console.log(res);
            if (res.success) {
              setDisplayResetForm(true);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else {
      validator.current.showMessages();
      setData({
        ...data,
        dummyVal: data?.dummyVal ?? 0 + 1,
      });
    }
    validator.current.purgeFields();
  };

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-8 col-lg-6">
              <div className="card">
                <div className="card-body">
                  {isLoading && <Loader />}
                  <h3 className="text-center">Reset Password</h3>
                  <br />
                  {displayResetForm ? (
                    <>
                      <Textfield
                        name="email"
                        id="email"
                        label="Email Address"
                        value={data.email}
                        onChange={handleChange}
                        validationStr="required|email"
                        validator={validator}
                      />
                      <Textfield
                        name="password"
                        id="password"
                        label="New Password"
                        value={data.password}
                        onChange={handleChange}
                        validator={validator}
                        type="password"
                      />
                      <Textfield
                        name="otp"
                        id="otp"
                        label="Enter OTP sent to your email"
                        value={data.otp}
                        onChange={handleChange}
                        validator={validator}
                      />
                    </>
                  ) : (
                    <Textfield
                      name="email"
                      id="email"
                      label="Please Enter Your Email address"
                      value={data.email}
                      onChange={handleChange}
                      validationStr="required|email"
                      validator={validator}
                    />
                  )}

                  <br />
                  <button
                    className="btn btn-secondary w-100"
                    onClick={() => onClickSubmit()}
                  >
                    Submit
                  </button>
                  <br />
                  <br />
                  <div className="text-right">
                    <Link to="/login">Log In</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
