import React from "react";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h1>404</h1>
                  <h4>Page not found!</h4>
                  <Link to="/">Back to Main Page</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
