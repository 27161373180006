import React from "react";

export default function Welcome() {
  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="">
                      <h4 className="header-title"></h4>
                    </div>
                  </div>

                  <blockquote className="blockquote text-center row">
                    {/* <div className="col-12 h1">Thank you!</div> */}
                    <div className="col-12 h5">
                      Your form has been submitted. A member of our team will
                      reach out to you shortly. Please contact us if you require
                      assistance in the meantime.
                    </div>
                    <div className="col-12 h5 text-muted">
                      <label>
                        Telephone: <a href="tel:780.436.9955">780.436.9955</a>
                      </label>
                      <br />
                      <label>
                        Toll Free:{" "}
                        <a href="tel:1.888.436.9955">1.888.436.9955</a>
                      </label>
                      <br />
                      <label>
                        Email:{" "}
                        <a href="mailto:invest@gold-im.com">
                          invest@gold-im.com
                        </a>
                      </label>
                    </div>

                    <div className="col-12 mt-3">
                      <a href="https://gold-im.com/">
                        <button
                          type="button"
                          className="btn btn-dark btn-lg width-lg"
                        >
                          Home
                        </button>
                      </a>
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
