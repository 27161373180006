import React from "react";
import BankReference from "../BankReference";
import Beneficiary from "../Beneficiary";
import Corporation from "../Corporation";
import Employment from "../Employment";
import IncomeBalanceSheet from "../IncomeBalanceSheet";
import Objectives from "../Objectives";
import Personal from "../Personal";
import Residence from "../Residence";
import Spouse from "../Spouse";
import Uploads from "../Uploads";

export default function Summary({
  data: {
    personal,
    employment,
    beneficiary,
    bankingReference,
    incomeBalanceSheet,
    objectives,
    residence,
    spouse,
    uploads,
    corporation,
  },
}) {
  return (
    <>
      <h4 className="header-title">Personal Information</h4>
      <Personal data={personal} isView />
      <br />

      <h4 className="header-title">Residence Information</h4>
      <Residence data={residence} isView />
      <br />

      <h4 className="header-title">Employment Information</h4>
      <Employment data={employment} isView />
      <br />

      {spouse.is_married && (
        <>
          <h4 className="header-title">Spouse's Information</h4>
          <Spouse data={spouse} isView />
          <br />
        </>
      )}

      <h4 className="header-title">Beneficiary Information</h4>
      <Beneficiary data={beneficiary} isView />
      <br />
      {/* 
			<h4 className="header-title">Banking Reference Information</h4>
			<BankReference data={bankingReference} isView />
			<br /> */}

      <h4 className="header-title">Income & Balance Sheet Information</h4>
      <IncomeBalanceSheet data={incomeBalanceSheet} isView />
      <br />

      <h4 className="header-title">Corporation's Information</h4>
      <Corporation data={corporation} isView />
      <br />

      <h4 className="header-title">Objective Information</h4>
      <Objectives data={objectives} isView />
      <br />

      <h4 className="header-title">Uploads</h4>
      <Uploads data={uploads} isView />
    </>
  );
}
