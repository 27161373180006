import React, { useState } from "react";
import countries from "../../data/json/citizen.json";
import Textfield from "../Utills/Textfield";
import Select from "../Utills/Select";
import CheckBox from "../Utills/CheckBox";
import TextfieldWithIcon from "../Utills/TextfieldWithIcon";
import { titles } from "../Personal";

export default function Spouse(props) {
  const { data, handleChange, validator, isView } = props;
  const [showVal, setShowVal] = useState(false);

  var currentDate = new Date();
  var dd = String(currentDate.getDate()).padStart(2, "0");
  var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = currentDate.getFullYear();

  currentDate = yyyy + "-" + mm + "-" + dd;

  validator?.current?.purgeFields();

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 mt-1">
        {!isView && (
          <CheckBox
            disabled={isView}
            name="is_married"
            id="is_married"
            value={data.is_married}
            onChange={handleChange}
            label="Are you married or do you have a common-law spouse?"
          />
        )}
      </div>

      {data.is_married ? (
        <>
          <div className="mb-1 col-12 col-md-3">
            <Select
              name="title"
              id="title"
              onChange={handleChange}
              label="Title"
              options={titles}
              value={data.title}
              disabled={isView}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              validator={data.is_married ? validator : null}
              name="first_name"
              id="first_name"
              onChange={handleChange}
              value={data.first_name}
              label="Spouse First Name"
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              // validator={data.is_married ? validator : null}
              name="middle_name"
              id="middle_name"
              onChange={handleChange}
              value={data.middle_name}
              label="Initials"
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              validator={data.is_married ? validator : null}
              name="last_name"
              id="last_name"
              onChange={handleChange}
              value={data.last_name}
              label="Last Name"
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Select
              disabled={isView}
              validator={validator}
              label="Employment"
              title={isView ? null : "If retired, specify previous employment."}
              id="employment"
              name="employment"
              value={data.employment}
              onChange={handleChange}
              options={[
                { val: "", opt: "- - -" },
                { val: "Self Employed", opt: "Self Employed" },
                { val: "Employed", opt: "Employed" },
                { val: "Retired", opt: "Retired" },
                { val: "Unemployed", opt: "Unemployed" },
                { val: "Other", opt: "Other" },
              ]}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              validator={
                data.is_married && data.employment !== "Unemployed"
                  ? validator
                  : null
              }
              name="occupation"
              id="occupation"
              onChange={handleChange}
              value={data.occupation}
              label="Occupation"
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              validator={
                data.is_married && data.employment !== "Unemployed"
                  ? validator
                  : null
              }
              name="employer"
              id="employer"
              onChange={handleChange}
              value={data.employer}
              label="Employer"
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              validator={
                data.is_married && data.employment !== "Unemployed"
                  ? validator
                  : null
              }
              name="business_type"
              id="business_type"
              onChange={handleChange}
              value={data.business_type}
              label="Type of Business"
              disabled={isView}
            />
          </div>

          <div className="mb-1 col-12 col-md-6">
            <Textfield
              disabled={isView}
              validator={data.is_married ? validator : null}
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              value={data.email}
              label="Email"
              validationStr="required|email"
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              // validator={data.is_married ? validator : null}
              type="tel"
              name="mobile_no"
              id="mobile_no"
              onChange={handleChange}
              value={data.mobile_no}
              label="Mobile No."
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              // validator={data.is_married ? validator : null}
              type="tel"
              name="office_no"
              id="office_no"
              onChange={handleChange}
              value={data.office_no}
              label="Office No."
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              // validator={data.is_married ? validator : null}
              type="tel"
              name="ext"
              id="ext"
              onChange={handleChange}
              value={data.ext}
              label="Ext."
            />
          </div>

          <div className="mb-1 col-12 col-md-6">
            <Textfield
              disabled={isView}
              // validator={data.is_married ? validator : null}
              type="tel"
              name="home_no"
              id="home_no"
              onChange={handleChange}
              value={data.home_no}
              label="Home No."
            />
          </div>

          <div className="mb-1 col-12 col-md-3">
            <Textfield
              disabled={isView}
              validator={data.is_married ? validator : null}
              type="date"
              name="dob"
              id="dob"
              onChange={handleChange}
              value={data.dob}
              label="Date of Birth"
              max={currentDate}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Select
              disabled={isView}
              validator={data.is_married ? validator : null}
              name="citizenship"
              id="citizenship"
              label="Citizenship"
              value={data.citizenship}
              onChange={handleChange}
              options={countries.data}
            />
          </div>
          <div className="mb-1 col-12 col-md-6">
            {/* <TextfieldWithIcon
							disabled={isView}
							validator={data.is_married ? validator : null}
							validationStr="required|size:9"
							type={showVal ? "text" : "password"}
							name="sin"
							id="sin"
							onChange={handleChange}
							value={data.sin}
							label="SIN #"
							icon={
								<span
									className="input-group-text px-2 py-0 font-18"
									onClick={() => {
										setShowVal(!showVal);
									}}
								>
									<i className="nopnt mdi mdi-eye-off"></i>
								</span>
							}
						/> */}

            <Textfield
              name="sin"
              id="sin"
              onChange={handleChange}
              label="SIN"
              value={data.sin}
              disabled={isView}
              validator={data.is_married ? validator : null}
              validationStr="required|size:9"
            />
          </div>
          <div className="mb-1 col-12">
            <span className="error">
              <br />
              {validator?.current?.message(
                "atleastOnePhone",
                `${data?.mobile_no ?? ""}${data?.home_no ?? ""}${data?.office_no ?? ""}${data?.ext ?? ""}`.trim(),
                "atleastOnePhone",
              )}
            </span>
          </div>
        </>
      ) : (
        isView && "None"
      )}
    </div>
  );
}
