import React from "react";
import { Link } from "react-router-dom";

export default function Welcome() {
  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="">
                      <h4 className="header-title"></h4>
                    </div>
                  </div>

                  <blockquote className="blockquote text-center row">
                    <div className="col-12 h2">Welcome to Gold Investment</div>

                    <div className="col-12 h4 text-muted">
                      Please fill out the following form pages to start your new
                      account process.
                    </div>

                    <div className="col-12 h4 text-muted mb-3">
                      We will be collecting the following details:
                    </div>

                    <div className="col-12 offset-md-2 col-md-4 text-left">
                      <div className="h4 mb-1">Details</div>
                      <hr className="mt-0 mb-1" />

                      <ul>
                        <li>Date of Birth, SIN# and Citizenship</li>
                        <li>Residence, Employment and Spouse</li>
                        <li>Income and Balance Sheet</li>
                      </ul>
                    </div>

                    <div className="col-12 col-md-4 text-left">
                      <div className="h4 mb-1">Uploads</div>
                      <hr className="mt-0 mb-1" />
                      <ul>
                        <li>Driver’s License and Passport</li>
                        <li>Void Cheque</li>
                        <li>Current Investment Statements</li>
                      </ul>
                    </div>

                    <div className="col-12 h4 text-muted mb-3">
                      If you are opening a corporate investment account we will
                      collect the following additional details:
                    </div>

                    <div className="col-12 offset-md-2 col-md-4 text-left">
                      <div className="h4 mb-1">Details</div>
                      <hr className="mt-0 mb-1" />

                      <ul>
                        <li>Business Name</li>
                        <li>Business Identification Number (BIN)</li>
                      </ul>
                    </div>

                    <div className="col-12 col-md-4 text-left">
                      <div className="h4 mb-1">Uploads</div>
                      <hr className="mt-0 mb-1" />
                      <ul>
                        <li>Certificate of Incorporation</li>
                        <li>Articles of Incorporation</li>
                        <li>Corporate void cheque</li>
                        <li>
                          Driver's License and Passport for each signing officer
                          or person controlling 10% or more of the corporation
                        </li>
                        <li>
                          Annual Returns or Proof of Filing (states percentage
                          of voting shares)
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 h4 text-muted mb-3">
                      Our admin team is happy to assist later with gathering
                      documents if you do not have them handy while completing
                      this form.
                    </div>

                    <div className="col-12 mt-3">
                      <Link to="/investmentAccountForm">
                        <button
                          type="button"
                          className="btn btn-dark btn-lg width-lg"
                        >
                          Get Started
                        </button>
                      </Link>
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
