import React from "react";

export default function Select(props) {
  const {
    name,
    label,
    onChange,
    options,
    id,
    value,
    title,
    validator,
    validationStr,
    disabled,
  } = props;
  return (
    <div className="form-group">
      <label htmlFor={id} className="col-form-label">
        {label}
        {title && <i className="fas fa-info-circle tip" tip={title}></i>}
      </label>
      <select
        disabled={disabled}
        className="custom-select"
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        autoComplete="new-password"
      >
        {options.map((option) => (
          <option key={option.val} value={option.val}>
            {option.opt}
          </option>
        ))}
      </select>
      {validator && (
        <span className="error">
          {validator.current.message(
            name,
            value,
            validationStr ? validationStr : "required",
          )}
        </span>
      )}
    </div>
  );
}
