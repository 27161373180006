import React from "react";

export default function Footer(props) {
  const { currentStep, onClickNext, onClickSave, onClickPrev, onClickCancel } =
    props;
  return (
    <footer className="footer position-fixed bottom-0 shdw-up p-0">
      <div className="container">
        <ul className="nav d-flex flex-row pt-2 px-2 px-md-0">
          <li className="pr-1 pr-md-2">
            <a
              className="btn arrow-left btn-secondary pl-3 pr-2 text-white prev"
              disabled={currentStep.step === 1}
              data-evt="prev"
              data-trg="save"
              onClick={onClickPrev}
            >
              Previous
            </a>
          </li>

          <li>
            <a
              className="btn btn-soft-secondary text-muted cancel"
              onClick={onClickCancel}
              data-evt="cancel"
            >
              Cancel
            </a>
          </li>

          <li className="d-flex flex-fill justify-content-end">
            <a
              className={`btn arrow-right pl-3 pr-4 text-white next
                ${currentStep.title === "Submit" ? "btn-success" : "btn-dark"}`}
              onClick={currentStep.step === 10 ? onClickSave : onClickNext}
              data-evt="next"
              data-trg="save"
              data-check="true"
            >
              {currentStep.title === "Submit" ? "Submit" : "Next"}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
