import { fetchAPI, parseBody } from "./common";
import { image } from "./index";

export async function getClient() {
  const id = localStorage.getItem("userId");
  const res = await fetchAPI(`/client/me`, "get");
  const client = await parseBody(res);

  return client;
}

export async function updatePersonalInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/personal/${id}`, "put", data);
      const personal = await parseBody(res);

      resolve(personal);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateResidenceInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/residence/${id}`, "put", data);
      const residence = await parseBody(res);

      resolve(residence);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateEmploymentInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/employment/${id}`, "put", data);
      const employment = await parseBody(res);

      resolve(employment);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateSpouseInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/spouse/${id}`, "put", data);
      const spouse = await parseBody(res);

      resolve(spouse);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateCorporationInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      if (data.files && data.files.length) {
        let files = [];
        for (let i = 0; i < data.files?.length; i++) {
          const file = data.files[i];
          if (file && typeof file != "string") {
            const url = await image.upload(file);
            files.push(url);
          }
        }

        data.files = files;
      }
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/corporation_fe/${id}`, "put", data);
      const corporation = await parseBody(res);

      resolve(corporation);
    } catch (err) {
      console.log("error", err);
      reject(err);
    }
  });
}

export async function updateBeneficiaryInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/beneficiary/${id}`, "put", data);
      const beneficiary = await parseBody(res);

      resolve(beneficiary);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateBalanceSheetInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/balance_sheet_fe/${id}`, "put", data);
      const balance_sheet = await parseBody(res);

      resolve(balance_sheet);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateObjectivesInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/objectives/${id}`, "put", data);
      const objectives = await parseBody(res);

      resolve(objectives);
    } catch (err) {
      reject(err);
    }
  });
}

export async function updateUploadInfo(data) {
  return new Promise(async (resolve, reject) => {
    try {
      delete data.id;
      let files = [];
      if (data.files && data.files.length > 0) {
        for (let i = 0; i < data.files?.length; i++) {
          const file = data.files[i];
          if (file && typeof file != "string") {
            const url = await image.upload(file);
            files.push(url);
          }
        }
      }
      const id = localStorage.getItem("userId");
      const res = await fetchAPI(`/client/uploads_fe/${id}`, "put", {
        files,
      });
      const upload = await parseBody(res);

      resolve(upload);
    } catch (err) {
      reject(err);
    }
  });
}
