import React from "react";

export default function CheckBox(props) {
  const { name, id, label, onChange, value, className, disabled, isCheckBox } =
    props;
  return isCheckBox ? (
    <div className="checkbox-secondary">
      <div
        className={`custom-control checkbox-success custom-checkbox ${
          className ?? ""
        }`}
      >
        <input
          disabled={disabled}
          type="checkbox"
          className="custom-control-input"
          id={id}
          name={name}
          checked={value}
          value={value}
          onChange={({ target: { name, checked } }) =>
            onChange({ target: { name, value: checked } })
          }
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  ) : (
    <div className="checkBox1">
      <label>{label}</label>
      <button
        className={`${!value ? "selectedNoBtn" : ""}`}
        type="button"
        onClick={() => onChange({ target: { name, value: false } })}
      >
        NO
      </button>
      <button
        className={`${value ? "selectedYesBtn" : ""}`}
        type="button"
        onClick={() => onChange({ target: { name, value: true } })}
      >
        YES
      </button>
    </div>
  );
}
