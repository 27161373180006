import React from "react";
import { Routes, Route } from "react-router-dom";
import Welcome from "../pages/Welcome";
import InvestmentAccountForm from "../pages/InvestmentAccountForm";
import ThankYou from "../pages/ThankYou";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ProtectedRoute from "./protectedRoute";
import Page404 from "../pages/Page404";
import ForgetPassword from "../pages/ForgetPassword";

export default function RoutesComp() {
  return (
    <Routes>
      <Route index element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="forget-password" element={<ForgetPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path="welcome" element={<Welcome />} />
        <Route
          path="investmentAccountForm"
          element={<InvestmentAccountForm />}
        />
        <Route path="thankyou" element={<ThankYou />} />
        <Route path="*" element={<Page404 />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
