import React from "react";
import Select from "../Utills/Select";

const beneficiaryDropdown = [
  { val: "", opt: " - - - " },
  { val: "Spouse", opt: "Spouse" },
  { val: "Estate", opt: "Estate" },
  { val: "Please discuss with me", opt: "Please discuss with me" },
];

export default function Beneficiary(props) {
  const { data, handleChange, validator, isView, isMarried } = props;
  console.log("isMarried", isMarried);
  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 col-md-6">
        <Select
          disabled={isView}
          validator={validator}
          name="account_holder"
          id="account_holder"
          value={data.account_holder}
          onChange={handleChange}
          options={
            isMarried
              ? beneficiaryDropdown
              : beneficiaryDropdown.filter((b) => b.val != "Spouse")
          }
          label="Account Holder's Beneficiary"
        />
      </div>
      {isMarried &&
        data.account_holder !== "Estate" &&
        data.account_holder !== "Please discuss with me" && (
          <div className="mb-1 col-12 col-md-4">
            <Select
              disabled={isView}
              validator={validator}
              name="account_spouse"
              id="account_spouse"
              value={data.account_spouse}
              onChange={handleChange}
              options={beneficiaryDropdown}
              label="Spouse's Beneficiary"
            />
          </div>
        )}
    </div>
  );
}
