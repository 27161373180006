import React, { useRef, useState } from "react";
import Textfield from "../../components/Utills/Textfield";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { register, verifyAccount } from "../../backend/auth";
import Loader from "../../components/Utills/Loader";

const aboutUsOptions = [
  {
    id: "referral_agent",
    name: "Referral Agent",
    inputLabel: "Referral Agent Name",
  },
  { id: "advertisement", name: "Advertisement", inputLabel: "Source" },
  { id: "website", name: "Website" },
  { id: "google", name: "Google" },
  { id: "other", name: "Other", inputLabel: "Please specify" },
];

export default function Register() {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    otp: "",
  });
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [displayVerifyAccount, setDisplayVerifyAccount] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Required!",
      },
      element: (message) => {
        let msg = message;
        if (msg.includes("confirm password")) {
          msg = "Confirm password doesn't match the entered password.";
        }
        return <div>{msg}</div>;
      },
    }),
  );

  const onClickRegister = () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      register({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        heard_from: selectedOption
          ? `${selectedOption}: ${data.selected_option_text}`
          : "",
      })
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            if (res.client?.id) {
              setUserData(res.client);
              setDisplayVerifyAccount(true);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      validator.current.showMessages();
      setData({
        ...data,
        dummyVal: data?.dummyVal ?? 0 + 1,
      });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
    validator.current.purgeFields();
  };

  const onClickVerifyCode = () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      verifyAccount({ email: data.email, otp: data.otp })
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            localStorage.setItem("userId", userData?.id);
            localStorage.setItem("token", userData?.token);
            window.location.href = "/welcome";
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      validator.current.showMessages();
      setData({
        ...data,
        dummyVal: data?.dummyVal ?? 0 + 1,
      });
    }
  };

  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-8 col-lg-6">
              <div className="card">
                <div className="card-body">
                  {isLoading && <Loader />}
                  {displayVerifyAccount ? (
                    <>
                      <h3 className="text-center">Verify your account</h3>
                      <br />
                      <Textfield
                        name="otp"
                        id="otp"
                        label="Please enter the code sent to your email address"
                        value={data.otp}
                        onChange={handleChange}
                        validator={validator}
                      />
                      <br />
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => onClickVerifyCode()}
                      >
                        Verify code
                      </button>
                    </>
                  ) : (
                    <>
                      <h3 className="text-center">Create New Account</h3>
                      <br />
                      <Textfield
                        name="first_name"
                        id="first_name"
                        label="First Name"
                        value={data.first_name}
                        onChange={handleChange}
                        validator={validator}
                      />
                      <Textfield
                        name="last_name"
                        id="last_name"
                        label="Last Name"
                        value={data.last_name}
                        onChange={handleChange}
                        validator={validator}
                      />
                      <Textfield
                        name="email"
                        id="email"
                        label="Email address"
                        value={data.email}
                        onChange={handleChange}
                        validationStr="required|email"
                        validator={validator}
                      />
                      <Textfield
                        type="password"
                        name="password"
                        id="password"
                        label="Password"
                        value={data.password}
                        onChange={handleChange}
                        validator={validator}
                      />
                      <Textfield
                        type="password"
                        name="confirm_password"
                        id="confirm_password"
                        label="Confirm Password"
                        value={data.confirm_password}
                        onChange={handleChange}
                        validator={validator}
                        validationStr={`required|in:${data.password}`}
                      />
                      <br />
                      <div className="form-group">
                        <label>How did you find out about us?</label>
                        {aboutUsOptions.map((o) => (
                          <div className="form-check">
                            <div className="row">
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={o.id}
                                  id={o.id}
                                  checked={selectedOption === o.name}
                                  onClick={() => setSelectedOption(o.name)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={o.id}
                                >
                                  {o.name}
                                </label>
                              </div>
                              <div className="col-9">
                                {selectedOption === o.name && o.inputLabel && (
                                  <Textfield
                                    name={o.id}
                                    id={o.id}
                                    value={data.selected_option_text}
                                    placeholder={o.inputLabel}
                                    onChange={({ target: { value } }) =>
                                      handleChange({
                                        target: {
                                          name: "selected_option_text",
                                          value,
                                        },
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <br />
                      <button
                        className="btn btn-secondary w-100"
                        onClick={() => onClickRegister()}
                      >
                        Create Account
                      </button>
                      <br />
                      <br />
                      <div className="w-100 text-right">
                        <Link to="/login">Already have an account?</Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
