import React from "react";

export default function TextfieldWithIcon(props) {
  const {
    name,
    id,
    type,
    value,
    onChange,
    label,
    placeholder,
    icon,
    validator,
    validationStr,
    step,
    disabled,
  } = props;
  return (
    <>
      {label && (
        <label htmlFor={id} className="col-form-label">
          {label}
        </label>
      )}
      <div className="input-group">
        <input
          type={type ? type : "text"}
          className="form-control exclude"
          placeholder={placeholder}
          name={name}
          id={id}
          onChange={onChange}
          value={value}
          min="0"
          step={step}
          disabled={disabled}
          autoComplete="new-password"
        />

        <div className="input-group-append">{icon}</div>
      </div>
      {validator && (
        <span className="error">
          {validator.current.message(
            name,
            value,
            validationStr ? validationStr : "required",
          )}
        </span>
      )}
    </>
  );
}
