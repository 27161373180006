import React from "react";
import stateProv from "../../data/json/stateprov.json";
import Textfield from "../Utills/Textfield";
import Select from "../Utills/Select";
import CheckBox from "../Utills/CheckBox";

export default function Residence(props) {
  const { data, handleChange, validator, isView } = props;

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    const obj = {
      [name]: value,
    };
    if (data.is_same) {
      obj[`m_${name}`] = value;
    }
    handleChange(e, obj);
  };

  validator?.current?.purgeFields();

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 col-md-6">
        <Textfield
          validator={validator}
          name="address"
          id="address"
          onChange={onChangeValue}
          value={data.address}
          label="Home Address"
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-6">
        <Textfield
          validator={validator}
          name="city"
          id="city"
          onChange={onChangeValue}
          value={data.city}
          label="City"
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Select
          validator={validator}
          name="country"
          id="country"
          onChange={onChangeValue}
          value={data.country}
          label="Country"
          disabled={isView}
          options={[
            { val: "CA", opt: "Canada" },
            { val: "US", opt: "United States" },
          ]}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Select
          validator={validator}
          name="province"
          id="province"
          onChange={onChangeValue}
          value={data.province}
          label="Province/State"
          disabled={isView}
          options={data.country ? stateProv[data.country] : []}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Textfield
          validator={validator}
          name="postal_code"
          id="postal_code"
          onChange={onChangeValue}
          value={data.postal_code}
          label="Postal/Zip"
          disabled={isView}
        />
      </div>
      {!isView && (
        <div className="mb-1 col-12 mt-1">
          <CheckBox
            isCheckBox
            name="is_same"
            id="is_same"
            label="Mailing Address is same as above."
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                const obj = {
                  m_address: data.address,
                  m_city: data.city,
                  m_country: data.country,
                  m_postal_code: data.postal_code,
                  m_province: data.province,
                  is_same: true,
                };
                handleChange(e, obj);
              } else {
                const obj = {
                  // m_address: "",
                  // m_city: "",
                  // m_country: "CA",
                  // m_postal_code: "",
                  // m_province: "",
                  is_same: false,
                };
                handleChange(e, obj);
              }
            }}
            value={data.is_same}
          />
        </div>
      )}
      {(!data.is_same || isView) && (
        <>
          <div className="mb-1 col-12 col-md-6">
            <Textfield
              validator={validator}
              name="m_address"
              id="m_address"
              onChange={handleChange}
              value={data.m_address}
              label="Mailing Address"
              disabled={isView}
            />
          </div>

          <div className="mb-1 col-12 col-md-6">
            <Textfield
              validator={validator}
              name="m_city"
              id="m_city"
              onChange={handleChange}
              value={data.m_city}
              label="City"
              disabled={isView}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Select
              validator={validator}
              name="m_country"
              id="m_country"
              onChange={handleChange}
              value={data.m_country}
              label="Country"
              disabled={isView}
              options={[
                { val: "CA", opt: "Canada" },
                { val: "US", opt: "United States" },
              ]}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Select
              validator={validator}
              name="m_province"
              id="m_province"
              onChange={handleChange}
              value={data.m_province}
              label="Province/State"
              disabled={isView}
              options={data.country ? stateProv[data.m_country] : []}
            />
          </div>
          <div className="mb-1 col-12 col-md-3">
            <Textfield
              validator={validator}
              name="m_postal_code"
              id="m_postal_code"
              onChange={handleChange}
              value={data.m_postal_code}
              label="Postal/Zip"
              disabled={isView}
            />
          </div>
        </>
      )}
    </div>
  );
}
