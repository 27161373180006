import React, { useEffect, useState } from "react";
import { updatePersonalInfo } from "../../backend/client";
import Loader from "../../components/Utills/Loader";

export default function FormContainer(props) {
  const {
    currentStep: { title, desc, id, step, steps },
    personalNotes,
  } = props;
  const [displayNotesModal, setDisplayNotesModal] = useState(false);
  const [displayHelpModal, setDisplayHelpModal] = useState(false);
  const [notes, setNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNotes(personalNotes);
  }, [personalNotes]);

  const onClickSaveNotes = async () => {
    setIsLoading(true);
    await updatePersonalInfo({ notes });
    setIsLoading(false);
    setDisplayNotesModal(false);
  };

  return (
    <>
      <div className="content-page m-0">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card mb-5">
                  <div className="card-body p-2 p-md-3">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h4 className="header-title">{title}</h4>
                        <p className="sub-header">{desc}</p>
                      </div>
                      <div className="d-none d-md-block col-md-6 align-right">
                        <ul className="progressindicator right">
                          {steps.map((s) => (
                            <React.Fragment key={s}>
                              {!s ? (
                                <>
                                  <li className="check" />
                                  <li className="bar" />
                                </>
                              ) : s == step ? (
                                <>
                                  <li className="active" page={title}>
                                    {s}
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li
                                    className={`bar inactive ${s == "10" ? "dash" : ""}`}
                                  ></li>
                                  <li className="">{s}</li>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                          <li
                            style={{
                              border: "none",
                              marginLeft: "20px",
                              display: "inline",
                            }}
                          >
                            <button
                              className="btn btn-info mr-1"
                              id="help-btn"
                              onClick={() => setDisplayHelpModal(true)}
                            >
                              Help
                            </button>
                            <button
                              className="btn btn-info"
                              id="help-btn"
                              onClick={() => setDisplayNotesModal(true)}
                            >
                              Client Notes
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <form
                      id={id}
                      method="newclient"
                      data-id="w8oHqAGRffiB9DTN5"
                    >
                      {props.children}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="helpModal"
        className="modal"
        style={{ display: displayHelpModal ? "block" : "none" }}
      >
        <div className="modal-content">
          <p>
            <h3>Please contact our admin team for assistance.</h3> <br />
            <label>Call or Text:</label>
            <a href="tel:780-436-9955">780-436-9955</a>
            <br />
            <label>Toll Free:</label>
            <a href="tel:1-888-436-9955">1-888-436-9955</a>
            <br />
            <label>Email:</label>
            <a href="mailto:clientexperience@gold-im.com">
              clientexperience@gold-im.com
            </a>
          </p>
          <div>
            <button
              onClick={() => setDisplayHelpModal(false)}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div
        id="clientNotesModal"
        className="modal"
        style={{ display: displayNotesModal ? "block" : "none" }}
      >
        <div className="modal-content">
          {isLoading && <Loader />}
          <p>
            <h3>Client Notes</h3>
            <textarea
              className="form-control"
              rows={5}
              value={notes}
              onChange={({ target: { value } }) => setNotes(value)}
            />
          </p>
          <div>
            <button onClick={() => onClickSaveNotes()} className="btn btn-info">
              Save
            </button>
            <button
              onClick={() => setDisplayNotesModal(false)}
              className="btn btn-secondary mr-1"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
