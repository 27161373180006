import React from "react";

export default function RadioButton(props) {
  const { id, name, value, onChange, label, className, disabled } = props;
  return (
    <div className={`radio radio-success ${className ?? "ml-2"}`}>
      <input
        type="radio"
        id={id}
        name={name}
        value={label}
        onChange={onChange}
        disabled={disabled}
        checked={value === label}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
