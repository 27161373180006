import React, { useState } from "react";
import Textfield from "../Utills/Textfield";
import TextfieldWithIcon from "../Utills/TextfieldWithIcon";
import { useEffect } from "react";

export const getNumber = (_str) => {
  var arr = _str.split("");
  var out = new Array();
  for (var cnt = 0; cnt < arr.length; cnt++) {
    if (isNaN(arr[cnt]) == false) {
      out.push(arr[cnt]);
    }
  }
  return Number(out.join(""));
};

const getFormatedNum = (value) => {
  var num = getNumber(value + "");
  let number = "";
  if (num !== 0) {
    number = num.toLocaleString();
  }

  return number;
};

export default function IncomeBalanceSheet(props) {
  const { data, handleChange, isView, isMarried } = props;

  useEffect(() => {
    if (handleChange) {
      let value =
        data?.assets?.reduce(
          (a, c) => a + Number(c?.amount?.replaceAll(",", "") ?? 0),
          0,
        ) ?? 0;
      value = getNumber(value + "");
      if (value !== 0) {
        value = value.toLocaleString();
      } else {
        value = "";
      }
      handleChange({
        target: {
          name: "total_assets",
          value,
        },
      });
    }
  }, [data.assets]);

  useEffect(() => {
    if (handleChange) {
      let value =
        data?.liabilities?.reduce(
          (a, c) => a + Number(c?.amount?.replaceAll(",", "") ?? 0),
          0,
        ) ?? 0;
      value = getNumber(value + "");
      if (value !== 0) {
        value = value.toLocaleString();
      } else {
        value = "";
      }
      handleChange({
        target: {
          name: "total_liabilities",
          value,
        },
      });
    }
  }, [data.liabilities]);

  const onClickRemoveAsset = (index) => {
    let b = [];
    data?.assets?.forEach((be, i) => {
      if (i !== index) {
        b.push(be);
      }
    });
    handleChange({
      target: {
        name: "assets",
        value: b.filter((v) => v.type),
      },
    });
  };

  const handleChangeAsset = (e, index, isName) => {
    let { name, value } = e.target;
    let b = [];
    data?.assets?.forEach((be, i) => {
      if (i === index) {
        var num = getNumber(value);
        let number = "";
        if (num !== 0) {
          number = num.toLocaleString();
        }
        // let v = value.toString().split(".");
        // if (v.length > 1 && v[1].length > 2) {
        //   value = Number(value).toFixed(2);
        // }
        b.push({ ...be, [name]: isName ? value : number });
      } else {
        b.push(be);
      }
    });
    handleChange({
      target: {
        name: "assets",
        value: b, //b.filter((v) => v.type),
      },
    });
  };

  const onClickRemoveLiability = (index) => {
    let b = [];
    data?.liabilities?.forEach((be, i) => {
      if (i !== index) {
        b.push(be);
      }
    });
    handleChange({
      target: {
        name: "liabilities",
        value: b, //b.filter((v) => v.type),
      },
    });
  };

  const handleChangeLiability = (e, index, isName) => {
    let { name, value } = e.target;
    let b = [];
    data?.liabilities?.forEach((be, i) => {
      if (i === index) {
        // let v = value.toString().split(".");
        // if (v.length > 1 && v[1].length > 2) {
        //   value = Number(value).toFixed(2);
        // }
        var num = getNumber(value);
        let number = "";
        if (num !== 0) {
          number = num.toLocaleString();
        }
        b.push({ ...be, [name]: isName ? value : number });
      } else {
        b.push(be);
      }
    });
    handleChange({
      target: {
        name: "liabilities",
        value: b,
      },
    });
  };

  const householdIncome =
    Number(data?.income?.replaceAll(",", "") ?? 0) +
    Number(data?.spouse_income?.replaceAll(",", "") ?? 0);

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 col-md-4">
        <Textfield
          label="Annual Income"
          // type="number"
          disabled={isView}
          name="income"
          id="income"
          value={getFormatedNum(data?.income ?? "")}
          onChange={handleChange}
        />
      </div>

      {isMarried && (
        <div className="mb-1 col-12 col-md-4">
          <Textfield
            label="Spouse’s Annual Income"
            // type="number"
            disabled={isView}
            name="spouse_income"
            id="spouse_income"
            value={getFormatedNum(data?.spouse_income ?? "")}
            // value={data.spouse_income}
            onChange={handleChange}
          />
        </div>
      )}
      <div className="mb-1 col-12 col-md-4">
        <Textfield
          disabled
          label="Annual Household Income"
          // value={householdIncome}
          value={getFormatedNum(householdIncome || "")}
          onChange={handleChange}
        />
      </div>
      <div className="multifld col-12 col-lg-6 pr-0 mb-0">
        <br />
        <div className="form-row pr-1 mb-1 justify-content-start">
          <div className="col-4 col-md-6">
            <label className="col-form-label m-100">Assets </label>
          </div>
          <div className="col-4 col-md-6">
            <label className="col-form-label m-100">Values </label>
          </div>
        </div>

        {data?.assets?.map((d, i) => (
          <div className="form-row multirow pr-1 mb-1" key={i}>
            <div className="col-6">
              <Textfield
                disabled={isView}
                name="type"
                id="type"
                value={d.type}
                onChange={(e) => handleChangeAsset(e, i, true)}
                list="classify"
              />
            </div>

            <div className="col pl-0">
              <TextfieldWithIcon
                disabled={isView}
                // type="number"
                step="any"
                id="amount"
                name="amount"
                // placeholder="$#.##"
                value={d.amount}
                onChange={(e) => handleChangeAsset(e, i)}
                icon={
                  !isView && (
                    <span
                      className="input-group-text bg-danger"
                      data-trg="rmv"
                      onClick={() => onClickRemoveAsset(i)}
                    >
                      <i className="nopnt fas fa-minus-circle text-white font-16"></i>
                    </span>
                  )
                }
              />
            </div>
          </div>
        ))}

        <div className="form-row pr-1 mb-1 justify-content-start">
          <div className="col-12 col-md-6">
            {!isView && (
              <div
                className="btn w-100 text-nowrap btn-success"
                style={{ marginBottom: "5px" }}
                data-trg="add"
                onClick={() => {
                  handleChange({
                    target: {
                      name: "assets",
                      value: [
                        ...data?.assets,
                        {
                          type: "",
                          amount: null,
                        },
                      ],
                    },
                  });
                }}
              >
                <i className="fas fa-plus-circle font-16 pr-mb-2"></i>
                Add
              </div>
            )}
          </div>

          <div className="col pl-0">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                data-lpignore="true"
                data-obj="profile"
                data-subobj="incomeassets"
                data-id="assettotal"
                data-get="assettotal"
                data-override="true"
                data-chk="curr"
                data-type="float"
                disabled
                id="assettotal"
                name="assettotal"
                value={"$ " + (data?.total_assets ?? "0")}
              />
              <span className="input-group-text w-50 text-nowrap justify-content-center">
                Total Assets
              </span>
            </div>
          </div>
        </div>

        <datalist id="classify">
          <select>
            <option>Cash</option>
            <option>Taxable Accounts</option>
            <option>Non-Registered Accounts</option>
            <option>RRSP</option>
            <option>TFSA</option>
            <option>RRIF</option>
            <option>Primary Residence</option>
            <option>Vacation Property</option>
          </select>
        </datalist>
      </div>

      <div className="multifld col-12 col-lg-6 pr-0">
        <br />
        <div className="form-row pr-1 mb-1 justify-content-start">
          <div className="col-4 col-md-6">
            <label className="col-form-label m-100">Liabilities </label>
          </div>
          <div className="col-4 col-md-6">
            <label className="col-form-label m-100">Values </label>
          </div>
        </div>
        {data?.liabilities?.map((d, i) => (
          <div className="form-row multirow pr-1 mb-1" key={i}>
            <div className="col-6">
              <Textfield
                disabled={isView}
                name="type"
                id="type"
                value={d.type}
                onChange={(e) => handleChangeLiability(e, i, true)}
                list="liabilitieslist"
              />
            </div>

            <div className="col pl-0">
              <TextfieldWithIcon
                disabled={isView}
                // type="number"
                step="any"
                id="amount"
                name="amount"
                value={d.amount}
                onChange={(e) => handleChangeLiability(e, i)}
                // placeholder="$#.##"
                icon={
                  !isView && (
                    <span
                      className="input-group-text bg-danger"
                      data-trg="rmv"
                      onClick={() => onClickRemoveLiability(i)}
                    >
                      <i className="nopnt fas fa-minus-circle text-white font-16"></i>
                    </span>
                  )
                }
              />
            </div>
          </div>
        ))}

        <div className="form-row pr-1 mb-1 justify-content-start">
          <div className="col-12 col-md-6">
            {!isView && (
              <div
                className={`btn w-100 text-nowrap btn-success ${data?.liabilities?.length == 3 ? "disabled" : ""}`}
                style={{ marginBottom: "5px" }}
                data-trg="add"
                onClick={() => {
                  if (data?.liabilities?.length < 3) {
                    handleChange({
                      target: {
                        name: "liabilities",
                        value: [
                          ...data?.liabilities,
                          {
                            type: "",
                            amount: null,
                          },
                        ],
                      },
                    });
                  }
                }}
              >
                <i className="fas fa-plus-circle font-16 pr-mb-2"></i>
                Add
              </div>
            )}
          </div>

          <div className="col pl-0">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                data-lpignore="true"
                data-obj="profile"
                data-subobj="incomeassets"
                data-id="liabilitiestotal"
                data-get="liabilitiestotal"
                data-override="true"
                data-chk="curr"
                data-type="float"
                disabled
                id="liabilitiestotal"
                name="liabilitiestotal"
                value={"$ " + (data?.total_liabilities ?? "0")}
              />
              <span className="input-group-text w-50 text-nowrap justify-content-center">
                Total Liabilities
              </span>
            </div>
          </div>
        </div>

        <datalist id="liabilitieslist">
          <select>
            <option>Mortgage</option>
            <option>Line of Credit</option>
            <option>Auto Loan</option>
            <option>Other</option>
          </select>
        </datalist>
      </div>

      <div className="mb-1 col-12 col-md-6 pl-md-1">
        <label for="" className="col-form-label w-100">
          Notes
        </label>
        <textarea
          disabled={isView}
          className="form-control"
          rows="3"
          data-obj="profile"
          data-subobj="incomeassets"
          data-id="note"
          name="note"
          value={data.note}
          onChange={handleChange}
        ></textarea>
      </div>

      <div className="form-inline align-content-end col-12 col-md-6">
        <div className="mb-1 col-12 col-md-8 offset-md-4 p-0">
          <div className="input-group w-100">
            <input
              type="text"
              className="form-control"
              inputMode=""
              placeholder=""
              autoComplete="off"
              data-lpignore="true"
              data-obj="profile"
              data-subobj="incomeassets"
              data-id="equity"
              data-get="equity"
              data-override="true"
              data-chk="curr"
              data-type="float"
              disabled
              name="equity"
              value={"$ " + (data?.equity ?? "0")}
            />

            <span className="input-group-text w-50 justify-content-center">
              Equity
            </span>
          </div>
        </div>

        <div className="mb-1 col-12 col-md-8 offset-md-4 p-0">
          <div className="input-group w-100">
            <input
              type="text"
              className="form-control"
              inputMode=""
              placeholder=""
              autoComplete="off"
              data-lpignore="true"
              data-obj="profile"
              data-subobj="incomeassets"
              data-id="debt"
              data-get="debt"
              data-override="true"
              data-type="float"
              disabled
              name="debt"
              value={`${data.dept} %`}
            />

            <span className="input-group-text w-50 justify-content-center">
              Debt / Equity
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
