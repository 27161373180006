import React, { useState } from "react";
import countries from "../../data/json/citizen.json";
import Textfield from "../Utills/Textfield";
import Select from "../Utills/Select";
import TextfieldWithIcon from "../Utills/TextfieldWithIcon";

export const titles = [
  { val: "", opt: "- - -" },
  { val: "Mr.", opt: "Mr." },
  { val: "Mrs.", opt: "Mrs." },
  { val: "Ms.", opt: "Ms." },
  { val: "Miss.", opt: "Miss." },
  { val: "Mlle.", opt: "Mlle." },
  { val: "Dr.", opt: "Dr." },
];

export default function Personal(props) {
  const { data, handleChange, validator, isView } = props;
  const [showVal, setShowVal] = useState(false);

  var currentDate = new Date();
  var dd = String(currentDate.getDate()).padStart(2, "0");
  var mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = currentDate.getFullYear();

  currentDate = yyyy + "-" + mm + "-" + dd;

  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 col-md-2">
        <Select
          name="title"
          id="title"
          onChange={handleChange}
          label="Title"
          options={titles}
          value={data.title}
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-4">
        <Textfield
          name="first_name"
          id="first_name"
          onChange={handleChange}
          label="First Name"
          value={data.first_name}
          validator={validator}
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-2">
        <Textfield
          name="initials"
          id="initials"
          onChange={handleChange}
          label="Initials"
          value={data.initials}
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-4">
        <Textfield
          name="last_name"
          id="last_name"
          onChange={handleChange}
          label="Last Name"
          value={data.last_name}
          validator={validator}
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-6">
        <Textfield
          name="email"
          id="email"
          onChange={handleChange}
          label="Email"
          value={data.email}
          validator={validator}
          validationStr="required|email"
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-6">
        <Textfield
          name="mobile_no"
          id="mobile_no"
          onChange={handleChange}
          label="Mobile No."
          value={data.mobile_no}
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-4">
        <Textfield
          name="office_no"
          id="office_no"
          onChange={handleChange}
          label="Office No."
          value={data.office_no}
          disabled={isView}
        />
      </div>

      <div className=" mb-1 col-12 col-md-2">
        <Textfield
          name="ext"
          id="ext"
          onChange={handleChange}
          label="Ext."
          value={data.ext}
          disabled={isView}
        />
      </div>

      <div className=" mb-1 col-12 col-md-6">
        <Textfield
          name="home_no"
          id="home_no"
          onChange={handleChange}
          label="Home No."
          value={data.home_no}
          disabled={isView}
        />
      </div>

      <div className=" mb-1 col-12 col-md-3">
        <Textfield
          type="date"
          name="dob"
          id="dob"
          onChange={handleChange}
          label="Date of Birth"
          value={data.dob}
          validator={validator}
          disabled={isView}
          max={currentDate}
        />
      </div>
      <div className=" mb-1 col-12 col-md-3">
        <Select
          name="citizenship"
          id="citizenship"
          onChange={handleChange}
          label="Citizenship"
          value={data.citizenship}
          options={countries.data}
          validator={validator}
          disabled={isView}
        />
      </div>
      <div className=" mb-1 col-12 col-md-6">
        {/* <TextfieldWithIcon
					disabled={isView}
					validator={validator}
					validationStr="required|size:9"
					type={showVal ? "text" : "password"}
					name="sin"
					id="sin"
					onChange={handleChange}
					value={data.sin}
					label="SIN #"
					icon={
						<span
							className="input-group-text px-2 py-0 font-18"
							onClick={() => {
								setShowVal(!showVal);
							}}
						>
							<i className="nopnt mdi mdi-eye-off"></i>
						</span>
					}
					
				/> */}

        <Textfield
          name="sin"
          id="sin"
          onChange={handleChange}
          label="SIN"
          value={data.sin}
          disabled={isView}
          validator={validator}
          validationStr="required|size:9"
        />
      </div>
      <div className="mb-1 col-12">
        <span className="error">
          <br />
          {validator?.current?.message(
            "atleastOnePhone",
            `${data?.mobile_no ?? ""}${data?.home_no ?? ""}${data?.office_no ?? ""}${data?.ext ?? ""}`.trim(),
            "atleastOnePhone",
          )}
        </span>
      </div>
    </div>
  );
}
