import React, { useRef, useState, useEffect } from "react";
import Textfield from "../../components/Utills/Textfield";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { login } from "../../backend/auth";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Utills/Loader";

export default function Login() {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        required: "Required!",
      },
    }),
  );

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/welcome");
    }
  }, []);

  const onClickLogin = () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      login({ email: data.email, password: data.password })
        .then((res) => {
          setIsLoading(false);
          if (res.success) {
            if (res.client?.is_verified) {
              localStorage.setItem("userId", res.client?.id);
              localStorage.setItem("token", res.client?.token);
              if (!res.client?.current_step) {
                window.location.href = "/welcome";
              } else if (res.client?.current_step == "completed") {
                window.location.href = "/thankyou";
              } else {
                window.location.href = "/investmentAccountForm";
              }
            } else {
              swal("", "Your email address is not verified", "info");
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      validator.current.showMessages();
      setData({
        ...data,
        dummyVal: data?.dummyVal ?? 0 + 1,
      });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div className="content-page m-0">
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-8 col-lg-6">
              <div className="card">
                <div className="card-body">
                  {isLoading && <Loader />}
                  <h3 className="text-center">Log In</h3>
                  <br />
                  <Textfield
                    name="email"
                    id="email"
                    label="Email address"
                    value={data.email}
                    onChange={handleChange}
                    validationStr="required|email"
                    validator={validator}
                  />
                  <Textfield
                    type="password"
                    name="password"
                    id="password"
                    label="Password"
                    value={data.password}
                    onChange={handleChange}
                    validator={validator}
                  />
                  <br />
                  <button
                    className="btn btn-secondary w-100"
                    onClick={() => onClickLogin()}
                  >
                    Log In
                  </button>
                  <br />
                  <br />
                  <div className="text-left" style={{ display: "inline" }}>
                    <Link to="/forget-password">Forgot / Reset Password</Link>
                  </div>
                  <div
                    className="text-right"
                    style={{
                      display: "inline",
                      float: "right",
                    }}
                  >
                    <Link to="/">Don't have an account?</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
