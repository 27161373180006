import axios from "../utils/axios";

export async function fetchAPI(route, method, data) {
  return axios({
    method,
    url: `${route}`,
    data,
  });
}

export async function parseBody(res, skipErrorCheck = false) {
  const body = await res.data;
  if (!skipErrorCheck) {
    if (body.error) throw new Error(body.message || body.errorObj.message);
    if (res.status !== 200) throw new Error(res.statusText);
  }
  return body;
}
