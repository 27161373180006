import React from "react";

export default function Textfield(props) {
  const {
    name,
    id,
    onChange,
    type,
    placeholder,
    label,
    value,
    children,
    className,
    title,
    list,
    validator,
    validationStr,
    step,
    disabled,
    max,
    min,
  } = props;

  return (
    <div className={`form-group ${className ?? ""}`}>
      {label && (
        <label htmlFor={id} className="col-form-label">
          {label} {validator && <span style={{ color: "red" }}>*</span>}
          {title && <i className="fas fa-info-circle tip" tip={title}></i>}
        </label>
      )}
      <input
        type={type ?? "text"}
        className="form-control"
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        id={id}
        value={value}
        list={list}
        min={min ?? "0"}
        step={step}
        disabled={disabled}
        max={max ?? null}
        autoComplete="new-password"
      />
      {children}
      {validator && (
        <span className="error">
          {validator?.current?.message(
            name,
            value,
            validationStr ? validationStr : "required",
          )}
        </span>
      )}
    </div>
  );
}
