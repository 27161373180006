/**
 * Trigger a dowload of file at `url` with the (optional) name `filename`.
 * This fetches the url avoiding same-orgin restrictions, if the file is on the
 * same orgin, then simply use an anchor <a /> element directly.
 * @param {string} url
 * @param {string | null} filename
 */
export async function download(url, filename) {
  const res = await fetch(url);
  const file = await res.blob();

  downloadAsFile(file, filename);
}

/**
 * Trigger a dowload the given `file` Blob with the (optional) name `filename`.
 * @param {Blob} file
 * @param {string | null} filename
 */
export function downloadAsFile(file, filename) {
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(file);
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}
