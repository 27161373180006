import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import Header from "./components/Header";
import Routes from "./routes";

const history = createBrowserHistory();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(history),
    }),
  ],
  environment: "production",
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <Router>
      <div
        id="wrapper"
        className="right-bar-disabled"
        data-sidebar-size="default"
        data-layout-mode="horizontal"
        data-sidebar-color="light"
        data-mode-color="light"
        data-layout-width="fluid"
        data-layout-menu-position="fixed"
        data-sidebar-showuser="false"
        data-topbar-color="dark"
      >
        <Header />
        <Routes />
      </div>
    </Router>
  );
}

export default App;
