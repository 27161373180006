import React from "react";
import LoaderGIF from "../../../loader.gif";

export default function Loader() {
  return (
    <div className={"overlay"}>
      <img className={"spinner"} width="50px" src={LoaderGIF} />
    </div>
  );
}
