import React from "react";

export default function Header() {
  return (
    <header id="header">
      <div
        id="header-inner-wrap"
        className="semi-transparent background--dark style1 no-transparent top-animate"
        data-headerscheme="background--dark"
      >
        <div
          id="header-wrap"
          className="clearfix"
          data-default-height="128"
          data-sticky-height="128"
        >
          <div className="logo">
            <a href="https://gold-im.com/">
              <img
                className="transparent-logo dark-scheme-logo"
                src="assets/logos/GIM-EN-July2017v4.png"
                alt="Gold Investment"
              />
              <img
                className="transparent-logo light-scheme-logo"
                src="assets/logos/GIM-EN-July2017v4.png"
                alt="Gold Investment"
              />
              <img
                className="normal-logo"
                src="assets/logos/GIM-EN-July2017v4.png"
                alt="Gold Investment"
              />
              <img
                className="sticky-logo"
                src="assets/logos/GIM-EN-July2017v4.png"
                alt="Gold Investment"
              />
            </a>
          </div>
          <div id="header-controls-right">
            <div className="header-code-widgets"></div>
            <div className="mobile-nav-controller-wrap">
              <div
                className="menu-controls mobile-nav-controller"
                title="Mobile Menu Controller"
              >
                <span className="be-mobile-menu-icon style2">
                  <span className="hamburger-line-1"></span>
                  <span className="hamburger-line-2"></span>
                  <span className="hamburger-line-3"></span>
                </span>
              </div>
            </div>
          </div>
          <div id="header-bottom-bar">
            <nav id="navigation" className="clearfix">
              <div className="menu">
                <ul
                  id="menu"
                  className="clearfix be-nav-link-effect-1 sf-js-enabled sf-arrows"
                >
                  <li
                    id="menu-item-799"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-121 current_page_item menu-item-799"
                  >
                    <a
                      title="Home"
                      href="https://gold-im.com/"
                      aria-current="page"
                    >
                      Home
                    </a>
                  </li>
                  <li
                    id="menu-item-507"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-507"
                  >
                    <a title="About" href="https://gold-im.com/about/">
                      About
                    </a>
                  </li>
                  <li
                    id="menu-item-1287"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1287"
                  >
                    <a title="Team" href="https://gold-im.com/team/">
                      Team
                    </a>
                  </li>
                  <li
                    id="menu-item-508"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-508"
                  >
                    <a
                      title="Investments"
                      href="https://gold-im.com/category/investments/"
                    >
                      Investments
                    </a>
                  </li>
                  <li
                    id="menu-item-506"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-506"
                  >
                    <a title="Physician" href="https://gold-im.com/physician/">
                      Physician
                    </a>
                  </li>
                  <li
                    id="menu-item-509"
                    className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-509"
                  >
                    <a title="Blog" href="https://gold-im.com/category/blog/">
                      Blog
                    </a>
                  </li>
                  <li
                    id="menu-item-505"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-505"
                  >
                    <a title="Contact" href="https://gold-im.com/contact/">
                      Contact
                    </a>
                  </li>
                  <li
                    id="menu-item-610"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-610"
                  >
                    {localStorage.getItem("userId") ? (
                      <a
                        title="Login"
                        className="sf-with-ul"
                        onClick={() => {
                          localStorage.clear();
                          window.location.href = "/login";
                        }}
                      >
                        Logout
                      </a>
                    ) : (
                      <a title="Login" href="/login" className="sf-with-ul">
                        Log In
                      </a>
                    )}
                    <span className="mobile-sub-menu-controller">
                      <i className="icon-icon_plus"></i>
                    </span>
                    <ul
                      className="sub-menu clearfix"
                      style={{ display: "none" }}
                    >
                      <li
                        id="menu-item-1494"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1494"
                      >
                        <a
                          title="GIM"
                          target="_blank"
                          rel="noopener"
                          href="https://www.ndexsystems.com/fengine/online/en/goldinvestsalogin.go"
                        >
                          GIM
                        </a>
                      </li>
                      <li
                        id="menu-item-1495"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495"
                      >
                        <a
                          title="Credential"
                          target="_blank"
                          rel="noopener"
                          href="https://csionline.credential.com/login.aspx"
                        >
                          Credential
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="header-mobile-navigation clearfix">
          <div className="mobile-menu">
            <ul id="mobile-menu" className="clearfix">
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-121 current_page_item menu-item-799">
                <a title="Home" href="https://gold-im.com/" aria-current="page">
                  Home
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-507">
                <a title="About" href="https://gold-im.com/about/">
                  About
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1287">
                <a title="Team" href="https://gold-im.com/team/">
                  Team
                </a>
              </li>
              <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-508">
                <a
                  title="Investments"
                  href="https://gold-im.com/category/investments/"
                >
                  Investments
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-506">
                <a title="Physician" href="https://gold-im.com/physician/">
                  Physician
                </a>
              </li>
              <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-509">
                <a title="Blog" href="https://gold-im.com/category/blog/">
                  Blog
                </a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-505">
                <a title="Contact" href="https://gold-im.com/contact/">
                  Contact
                </a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-610">
                {localStorage.getItem("userId") ? (
                  <a
                    title="Logout"
                    onClick={() => {
                      localStorage.clear();
                    }}
                  >
                    Logout
                  </a>
                ) : (
                  <a title="Login" href="/login">
                    Log In
                  </a>
                )}
                <span className="mobile-sub-menu-controller">
                  <i className="icon-arrow-right5"></i>
                </span>
                <ul className="sub-menu clearfix">
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1494">
                    <a
                      title="GIM"
                      target="_blank"
                      rel="noopener"
                      href="https://www.ndexsystems.com/fengine/online/en/goldinvestsalogin.go"
                    >
                      GIM
                    </a>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1495">
                    <a
                      title="Credential"
                      target="_blank"
                      rel="noopener"
                      href="https://csionline.credential.com/login.aspx"
                    >
                      Credential
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
