import { fetchAPI, parseBody } from "./common";

export async function login(data) {
  const res = await fetchAPI(`/client_auth/login`, "post", data);
  const login = await parseBody(res);

  return login;
}

export async function register(data) {
  const res = await fetchAPI(`/client_auth/register`, "post", data);
  const register = await parseBody(res);

  return register;
}

export async function verifyAccount(data) {
  const res = await fetchAPI(`/client_auth/verify-account`, "post", data);
  const verifyAcct = await parseBody(res);

  return verifyAcct;
}

export async function requestOTP(data) {
  const res = await fetchAPI(`/client_auth/reset-password-otp`, "post", data);
  const verifyAcct = await parseBody(res);

  return verifyAcct;
}

export async function resetPassword(data) {
  const res = await fetchAPI(`/client_auth/reset-password`, "patch", data);
  const verifyAcct = await parseBody(res);

  return verifyAcct;
}
