import React from "react";
import stateProv from "../../data/json/stateprov.json";
import Textfield from "../Utills/Textfield";
import Select from "../Utills/Select";

export default function Employment(props) {
  const { data, handleChange, validator, isView } = props;
  validator?.current?.purgeFields();
  return (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="mb-1 col-12 col-md-3">
        <Select
          disabled={isView}
          validator={validator}
          label="Employment"
          title={isView ? null : "If retired, specify previous employment."}
          id="employment"
          name="employment"
          value={data.employment}
          onChange={handleChange}
          options={[
            { val: "", opt: "- - -" },
            { val: "Self Employed", opt: "Self Employed" },
            { val: "Employed", opt: "Employed" },
            { val: "Retired", opt: "Retired" },
            { val: "Unemployed", opt: "Unemployed" },
            { val: "Other", opt: "Other" },
          ]}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Textfield
          validator={data.employment !== "Unemployed" ? validator : null}
          name="occupation"
          id="occupation"
          onChange={handleChange}
          value={data.occupation}
          label="Occupation"
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-3">
        <Textfield
          validator={data.employment !== "Unemployed" ? validator : null}
          name="employer"
          id="employer"
          onChange={handleChange}
          value={data.employer}
          label="Employer"
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-3">
        <Textfield
          validator={data.employment !== "Unemployed" ? validator : null}
          name="business_type"
          id="business_type"
          onChange={handleChange}
          value={data.business_type}
          label="Type of Business"
          disabled={isView}
        />
      </div>

      <div className="mb-1 col-12 col-md-6">
        <Textfield
          name="business_address"
          id="business_address"
          onChange={handleChange}
          value={data.business_address}
          label="Business Address"
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-6">
        <Textfield
          name="city"
          id="city"
          onChange={handleChange}
          value={data.city}
          label="City"
          disabled={isView}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Select
          name="country"
          id="country"
          onChange={handleChange}
          value={data.country}
          label="Country"
          disabled={isView}
          options={[
            { val: "CA", opt: "Canada" },
            { val: "US", opt: "United States" },
          ]}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Select
          name="province"
          id="province"
          onChange={handleChange}
          value={data.province}
          label="Province/State"
          disabled={isView}
          options={data.country ? stateProv[data.country] : []}
        />
      </div>
      <div className="mb-1 col-12 col-md-3">
        <Textfield
          name="postal_code"
          id="postal_code"
          onChange={handleChange}
          value={data.postal_code}
          label="Postal/Zip"
          disabled={isView}
        />
      </div>
    </div>
  );
}
