import React from "react";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import swal from "sweetalert";
import * as backend from "../../backend";
import { downloadAsFile } from "../../utils/download";

export default function Uploads(props) {
  const { data, handleChange, isView } = props;
  console.log({data});
  const fileTypes = [
    "JPEG",
    "PNG",
    "GIF",
    "PDF",
    "DOC",
    "DOCX",
    "JPG",
    "CSV",
  ];

  const onSelectFiles = (files) => {
    if (files?.length > 10) {
      swal("", "You can select a maximum of 10 files only", "info");
    } else {
      handleChange({ target: { name: "files", value: [...(data?.files ?? []), ...files] } });
    }
  };

  const removeFile = (index) => {
    handleChange({
      target: {
        name: "files",
        value: data.files.filter((_, i) => i !== index),
      },
    });
  };

  const viewFile = async (file) => {
    if (typeof file?.url == "string") {
      const blob = await backend.image.get(file.id);
      downloadAsFile(blob, file.url);
    } else {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl);
    }
  };

  return isView ? (
    <>
      {data?.files?.length > 0 ? (
        <div className="col-12">
          <h5 className="my-2">
            <b>Uploaded files:</b>
          </h5>
          {data.files && data.files.map(
            (f, i) =>
              f && (
                <div key={i}>
                  {i + 1}){" "}
                  <a className="fileName" onClick={() => viewFile(f)}>
                    {typeof f.url == "string" ? f.url : f.name}
                  </a>
                </div>
              ),
          )}
        </div>
      ) : (
        <div> None</div>
      )}
    </>
  ) : (
    <div className="form-row card-frame p-1 p-md-3 rounded-2">
      <div className="form-group pr-2 mb-1 col-12 col-md-5 fileUpload">
        <FileUploader
          multiple
          handleChange={onSelectFiles}
          name="files"
          types={fileTypes}
          maxSize="100"
        >
          <div className="DragDrop" style={{ height: "100%" }}>
            <div className="uppy-Root" dir="ltr">
              <button
                type="button"
                className="uppy-u-reset uppy-DragDrop-container uppy-DragDrop--isDragDropSupported"
                style={{ width: "100%", height: "80%" }}
              >
                <div className="uppy-DragDrop-inner">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    className="uppy-c-icon uppy-DragDrop-arrow"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  <div className="uppy-DragDrop-label">
                    Drop here or
                    <span className="uppy-DragDrop-browse"> browse</span>
                  </div>
                  <span className="uppy-DragDrop-note">All files</span>
                </div>
              </button>
            </div>
          </div>
        </FileUploader>
      </div>
      <div className="form-group mb-1 col-12 col-md-7">
        <h5 className="my-2">
          <b>Upload the following files:</b>
        </h5>

        <ul className="mb-1 ml-1">
          <li className="mb-0">Driver’s License and Passport</li>
          <li className="mb-0">Void Cheque</li>
          <li className="mb-0">Current Investment Account Statements</li>
        </ul>

        <h5 className="my-2">
          <b>Files:</b>
        </h5>
        <div className="row mx-n1 no-gutters">
          {data.files && data.files.map(
            (file, i) =>
              file && (
                <div
                  className="file col-12 col-md-6"
                  id={file.name}
                  key={file.name}
                  title={file.name}
                >
                  <div className="row flex-row flex-nowrap align-items-center card mx-1 mb-1 p-060 shadow-none border">
                    <div className="col-auto p-0">
                      <div className="avatar-sm">
                        <span className="m-2 avatar-title bg-light text-secondary rounded">
                          <i className="mdi mdi-file-outline font-28"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col px-0 overflow-hidden">
                      <div className="font-weight-medium overflow">
                        {file.name ?? file.url}
                      </div>
                      <p className="mb-0 text-muted font-13">
                        {file.lastModifiedDate ? `${moment(file.lastModifiedDate).format("L")} ${moment(file.lastModifiedDate).format("LT")} ` : 'Uploaded'}
                        <span className="mb-0 ml-1 font-13 overflow floatRight">
                          {file.size && Math.floor(file.size / 1000) + "KB"}
                        </span>
                      </p>
                    </div>
                    <div className="col-auto p-0">
                      <button
                        className="btn btn-lightx px-1 "
                        type="button"
                        onClick={() => removeFile(i)}
                      >
                        <i
                          className="mdi mdi-delete"
                          style={{
                            color: "red",
                            fontSize: "18px",
                          }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-lightx px-1 "
                        type="button"
                        onClick={() => viewFile(file)}
                      >
                        <i
                          className="mdi mdi-eye"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
}
