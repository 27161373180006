import axios from "axios";
import swal from "sweetalert";
import { API_URL } from "../constants/api";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalConfig = error.config;
    if (
      originalConfig.url !== "client_auth/login" &&
      originalConfig.url !== "client_auth/register" &&
      originalConfig.url !== "/client_auth/verify-account" &&
      error.response
    ) {
      if (error.response.status === 403 || error.response.status === 401) {
        window.location.href = "/login";
      }
    }
    swal(
      "Oops!",
      error?.response?.data?.error?.message ?? error.message,
      "error",
    );
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong",
    );
  },
);

export default axiosInstance;
